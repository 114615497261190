import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import qs from 'qs';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_API_LOCAL }),
  prepareHeaders: async (headers) => {
    const accessToken = localStorage.getItem('access');
    if (accessToken) {
      headers.set('authorization', `Bearer${accessToken}`);
    }
    return headers;
  },
  endpoints: (builder) => ({
    login: builder.mutation({
      query(body) {
        return {
          url: 'api/token/',
          method: 'POST',
          body,
        };
      },
    }),
    signup: builder.mutation({
      query(body) {
        return {
          url: 'clients/',
          method: 'POST',
          body,
        };
      },
    }),
    clientSteps: builder.mutation({
      query(body) {
        return {
          url: 'client-steps/',
          method: 'GET',
          body,
        };
      },
    }),

    verifyDocument: builder.mutation({
      query(body) {
        const queryParams = new URLSearchParams(body).toString();
        return {
          url: `verify-document/?${queryParams}`,
          method: 'GET',
        };
      },
    }),
    getContracts: builder.query({
      query: () => 'contracts/',
    }),
    createContract: builder.mutation({
      query(body) {
        return {
          url: 'create-contract/',
          method: 'POST',
          body,
          responseType: 'blob',
        };
      },
    }),
    createQrCode: builder.mutation({
      query(body) {
        return {
          url: 'create-qrcode/',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const getContracts = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/contracts/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const setPracticeData = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.patch(`${process.env.REACT_APP_BASE_API_LOCAL}/practice-data/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const createClientAccessCredentials = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/client-access-credentials/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const editClientInfo = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.patch(`${process.env.REACT_APP_BASE_API_LOCAL}/clients/${body.id}/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const editClientAccessCredentials = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API_LOCAL}/client-access-credentials/${body.id}/`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const updateClientPassword = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/update-client-password/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const setFirstLoginPassword = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/set-first-login-password/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updateClientPasswordAdmin = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/update-client-password-admin/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const uploadClientW9Copy = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/client-w9-copy/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'multipart/form-data',
    },
  });
  return response;
};

export const getClients = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/clients/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const resetPassword = async (body) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/password-reset/`, body);
  return response;
};

export const resetPasswordComplete = async (uid, token, password) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/password-reset-complete/`, {
    uid,
    token,
    new_password: password,
  });
  return response;
};

export const getClientContractFile = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/get-client-contract-file/`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getClientContracts = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/contracts/`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getClientW9Copy = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/client-w9-copy/`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getClientContractDetails = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/get-client-contract-details/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const signClientContract = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/sign-client-contract/${params.id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getClientSteps = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/client-steps/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getCommonMedicalPlatforms = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/common-medical-platforms/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getPracticeData = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/practice-data/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getClientAccessCredentials = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/client-access-credentials/`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteClientAccessCredentials = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API_LOCAL}/client-access-credentials/${params.id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const lockClientAccount = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/lock-client-account/`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const unlockClientAccount = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/unlock-client-account/`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getSupportTickets = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/support-tickets/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getSupportTicket = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/support-tickets/${params.id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getSupportTicketMessages = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/support-tickets/`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const createSupportTicket = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/support-tickets/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const closeSupportTicket = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/close-support-ticket/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const createSupportTicketMessage = async (values) => {
  const token = localStorage.getItem('access');
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/support-ticket-messages/`, values, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const createNewClient = async (values) => {
  const token = localStorage.getItem('access');
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/clients/`, values, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const signup = async (values) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/signup/`, values, {});
  return response;
};

export const getSupportTicketMessageFile = async (params) => {
  const token = localStorage.getItem('access');
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_LOCAL}/support-ticket-message-files/${params.id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getDashboardInfo = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/dashboard-info/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getClaimsDashboardFilterValues = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/claims-dashboard-filter-values/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getClaimsAnalysisFilterValues = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/claims-analysis-filter-values/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getBackgroundTasks = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/background-tasks/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getClaimsAnalysisInfo = async (startDate, endDate, selectedDoctor, selectedPractice) => {
  const token = localStorage.getItem('access');

  const params = {
    start_date: startDate || undefined,
    end_date: endDate || undefined,
    doctor: selectedDoctor || undefined,
    practice: selectedPractice || undefined,
  };

  const queryString = qs.stringify(params, { skipNulls: true });

  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/claims-analysis-info/?${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const claimFile = async (file, clientId) => {
  const token = localStorage.getItem('access');

  const formData = new FormData();
  formData.append('file', file);
  formData.append('client_id', clientId);

  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/process-claims-file/`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
};

export const getClaimsDashboardInfo = async (
  startDate,
  endDate,
  selectedInsuranceType,
  selecteClaimPrimaryPayer,
  selectedDoctor,
  selectedClaimStatus
) => {
  const token = localStorage.getItem('access');

  const params = {
    start_date: startDate || undefined,
    end_date: endDate || undefined,
    insurance_type: selectedInsuranceType || undefined,
    claim_primary_payer: selecteClaimPrimaryPayer || undefined,
    doctor: selectedDoctor || undefined,
    claim_status: selectedClaimStatus || undefined,
  };

  const queryString = qs.stringify(params, { skipNulls: true });

  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/claims-dashboard-info/?${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const createInvoice = async (body) => {
  const token = localStorage.getItem('access');
  const response = await axios.post(`${process.env.REACT_APP_BASE_API_LOCAL}/create-invoice/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
  return response;
};

export const getInvoices = async () => {
  const token = localStorage.getItem('access');
  const response = await axios.get(`${process.env.REACT_APP_BASE_API_LOCAL}/invoices/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const {
  useLoginMutation,
  useSignupMutation,
  useNewClientMutation,
  useClientStepsMutation,
  useVerifyDocumentMutation,
  useCreateContractMutation,
  useCreateQrCodeMutation,
  useGetContractsQuery,
} = api;
