import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useFormik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { getUserGroups } from '../utils';
import { createInvoice, getClients } from '../services/api';

const validationSchema = Yup.object({
  invoice_number: Yup.number()
    .required('Invoice Number is required')
    .positive('Invoice Number must be a positive number')
    .integer('Invoice Number must be an integer'),

  client_id: Yup.string().required('Client is required'),

  doctor_name: Yup.string().required('Doctor Name is required'),

  email: Yup.string().email('Invalid email address').required('Client Email is required'),

  phone: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),

  address_line_1: Yup.string().required('Address 1 is required'),

  address_line_2: Yup.string(),

  invoice_date: Yup.date('Enter the Invoice date').nullable().required('Invoice is required'),

  service_period_start: Yup.date('Enter the Service Period start date')
    .nullable()
    .required('Service Period start date is required'),
  service_period_end: Yup.date('Enter the Service Period end date')
    .nullable()
    .required('Service Period end date is required'),

  due_date: Yup.date('Enter the Due date').nullable().required('Due date is required'),

  percentage_terms: Yup.number()
    .min(0, 'Terms must be at least 0%')
    .max(100, 'Terms must be at most 100%')
    .required('Terms are required'),

  service_name: Yup.string().required('Service Name is required'),

  services: Yup.array()
    .of(
      Yup.object().shape({
        serial_number: Yup.number().notRequired(),
        name: Yup.string().required('Service name is required'),
        fee: Yup.number()
          .typeError('Fee must be a number')
          .positive('Fee must be greater than zero')
          .required('Fee is required'),
      })
    )
    .min(1, 'At least one service must be added')
    .required('Services are required'),
});

export default function NewInvoicePage() {
  const navigate = useNavigate();
  const [after, setAfter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);

  const formik = useFormik({
    initialValues: {
      invoice_number: 0,
      email: '',
      doctor_name: '',
      phone: '',
      address_line_1: '',
      address_line_2: '',
      invoice_date: null,
      service_period_start: null,
      service_period_end: null,
      due_date: null,
      percentage_terms: 3.5,
      service_name: '',
      services: [{ serial_number: 1, name: '', fee: 0 }],
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const response = await createInvoice(values);
        resetForm();
        setAfter(true);
        toast.success('Invoice generated successfully');
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
        toast.error('Error creating Invoice. Try again later.');
      }
    },
  });
  const handleFieldBlur = (fieldName) => {
    formik.setFieldTouched(fieldName, true, true);
  };

  useEffect(() => {
    const userGroups = getUserGroups();
    if (!userGroups.includes('admin') && !userGroups.includes('associate')) {
      navigate('/dashboard', { replace: true });
    } else {
      getClients()
        .then((clients) => {
          setClients(clients.data);
        })
        .catch(() => {
          const message = 'Cannot get clients, try again later.';
          toast.error(message);
        });
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | New Invoice</title>
      </Helmet>

      <Grid container spacing={3}>
        {after ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Typography variant="h4" marginBottom={2}>
                The invoice has been created successfully!
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = pdfUrl;
                  link.download = 'invoice.pdf';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                Download Invoice
              </Button>
              <Button onClick={() => navigate('/invoices')}>
                {' '}
                <ArrowBackIcon /> Go Back
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Grid item xs={12} md={2} lg={3} />
            <Grid item xs={12} md={8} lg={6}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5rem' }}>
                <IconButton onClick={() => navigate('/invoices')}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4">New Invoice</Typography>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    id="invoice_number"
                    name="invoice_number"
                    label="Invoice No."
                    value={formik.values.invoice_number}
                    onChange={formik.handleChange}
                    onBlur={() => handleFieldBlur('invoice_number')}
                    error={formik.touched.invoice_number && Boolean(formik.errors.invoice_number)}
                    helperText={formik.touched.invoice_number && formik.errors.invoice_number}
                  />
                  <TextField
                    id="client_id"
                    name="client_id"
                    label="Client"
                    select
                    value={formik.values.client_id}
                    onChange={formik.handleChange}
                    onBlur={() => handleFieldBlur('client_id')}
                    error={formik.touched.client_id && Boolean(formik.errors.client_id)}
                    helperText={formik.touched.client_id && formik.errors.client_id}
                  >
                    {clients?.map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.practice_name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="doctor_name"
                    name="doctor_name"
                    label="Doctor Name"
                    value={formik.values.doctor_name}
                    onChange={formik.handleChange}
                    onBlur={() => handleFieldBlur('doctor_name')}
                    error={formik.touched.doctor_name && Boolean(formik.errors.doctor_name)}
                    helperText={formik.touched.doctor_name && formik.errors.doctor_name}
                  />
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={() => handleFieldBlur('email')}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    id="phone"
                    name="phone"
                    label="Phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={() => handleFieldBlur('phone')}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />

                  <TextField
                    id="address_line_1"
                    name="address_line_1"
                    label="Address 1"
                    value={formik.values.address_line_1}
                    onChange={formik.handleChange}
                    onBlur={() => handleFieldBlur('address_line_1')}
                    error={formik.touched.address_line_1 && Boolean(formik.errors.address_line_1)}
                    helperText={formik.touched.address_line_1 && formik.errors.address_line_1}
                  />

                  <TextField
                    id="address_line_2"
                    name="address_line_2"
                    label="Address 2"
                    value={formik.values.address_line_2}
                    onChange={formik.handleChange}
                    onBlur={() => handleFieldBlur('address_line_2')}
                    error={formik.touched.address_line_2 && Boolean(formik.errors.address_line_2)}
                    helperText={formik.touched.address_line_2 && formik.errors.address_line_2}
                  />

                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          id="invoice_date"
                          name="invoice_date"
                          label="Date"
                          value={formik.values.invoice_date ? new Date(formik.values.invoice_date) : null}
                          onChange={(value) => {
                            const formattedDate = value ? dayjs(value).format('YYYY-MM-DD') : null;
                            formik.setFieldValue('invoice_date', formattedDate);
                          }}
                          onBlur={() => handleFieldBlur('invoice_date')}
                          error={formik.touched.invoice_date && Boolean(formik.errors.invoice_date)}
                          helperText={formik.touched.invoice_date && formik.errors.invoice_date}
                          sx={{ width: '100%' }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {formik.touched.invoice_date && formik.errors.invoice_date && (
                      <FormHelperText error>{formik.errors.invoice_date}</FormHelperText>
                    )}
                  </FormControl>
                  <div style={{ display: 'flex', width: '100%', gap: 20 }}>
                    <FormControl sx={{ width: '100%' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            id="service_period_start"
                            name="service_period_start"
                            label="Service Period Start"
                            value={
                              formik.values.service_period_start ? new Date(formik.values.service_period_start) : null
                            }
                            onChange={(value) => {
                              const formattedDate = value ? dayjs(value).format('YYYY-MM-DD') : null;
                              formik.setFieldValue('service_period_start', formattedDate);
                            }}
                            onBlur={() => handleFieldBlur('service_period_start')}
                            error={formik.touched.service_period_start && Boolean(formik.errors.service_period_start)}
                            helperText={formik.touched.service_period_start && formik.errors.service_period_start}
                            sx={{ width: '100%' }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {formik.touched.service_period_start && formik.touched.service_period_start && (
                        <FormHelperText error>{formik.touched.service_period_start}</FormHelperText>
                      )}
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            id="service_period_end"
                            name="service_period_end"
                            label="Service Period End"
                            value={formik.values.service_period_end ? new Date(formik.values.service_period_end) : null}
                            onChange={(value) => {
                              const formattedDate = value ? dayjs(value).format('YYYY-MM-DD') : null;
                              formik.setFieldValue('service_period_end', formattedDate);
                            }}
                            onBlur={() => handleFieldBlur('service_period_end')}
                            error={formik.touched.service_period_end && Boolean(formik.errors.service_period_end)}
                            helperText={formik.touched.service_period_end && formik.errors.service_period_end}
                            sx={{ width: '100%' }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {formik.touched.service_period_end && formik.touched.service_period_end && (
                        <FormHelperText error>{formik.touched.service_period_end}</FormHelperText>
                      )}
                    </FormControl>
                  </div>

                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          id="due_date"
                          name="due_date"
                          label="Due Date"
                          value={formik.values.due_date ? new Date(formik.values.due_date) : null}
                          onChange={(value) => {
                            const formattedDate = value ? dayjs(value).format('YYYY-MM-DD') : null;
                            formik.setFieldValue('due_date', formattedDate);
                          }}
                          onBlur={() => handleFieldBlur('due_date')}
                          error={formik.touched.due_date && Boolean(formik.errors.due_date)}
                          helperText={formik.touched.due_date && formik.errors.due_date}
                          sx={{ width: '100%' }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {formik.touched.due_date && formik.errors.due_date && (
                      <FormHelperText error>{formik.errors.due_date}</FormHelperText>
                    )}
                  </FormControl>

                  <TextField
                    id="percentage_terms"
                    name="percentage_terms"
                    label="Terms"
                    value={formik.values.percentage_terms}
                    onChange={formik.handleChange}
                    onBlur={() => handleFieldBlur('percentage_terms')}
                    error={formik.touched.percentage_terms && Boolean(formik.errors.percentage_terms)}
                    helperText={formik.touched.percentage_terms && formik.errors.percentage_terms}
                    InputProps={{
                      endAdornment: (
                        <>
                          <span>%</span>
                          <IconButton
                            onClick={() => {
                              const value =
                                formik.values.percentage_terms === ''
                                  ? 0
                                  : parseFloat(formik.values.percentage_terms, 10);
                              formik.setFieldValue('percentage_terms', value - 0.5);
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              const value =
                                formik.values.percentage_terms === ''
                                  ? 0
                                  : parseFloat(formik.values.percentage_terms, 10);
                              formik.setFieldValue('percentage_terms', value + 0.5);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </>
                      ),
                    }}
                  />
                  <TextField
                    id="service_name"
                    name="service_name"
                    label="Service Name"
                    value={formik.values.service_name}
                    onChange={formik.handleChange}
                    onBlur={() => handleFieldBlur('service_name')}
                    error={formik.touched.service_name && Boolean(formik.errors.service_name)}
                    helperText={formik.touched.service_name && formik.errors.service_name}
                  />
                  <InputLabel id="services-label">Services Breakdown</InputLabel>
                  <FormControl>
                    {formik.values.services.map((service, index) => (
                      <div
                        key={index}
                        style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '20px' }}
                      >
                        <TextField
                          name={`services[${index}].name`}
                          label="Service"
                          fullWidth
                          value={service.name || ''}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.services?.[index]?.name && Boolean(formik.errors.services?.[index]?.name)
                          }
                          helperText={formik.touched.services?.[index]?.name && formik.errors.services?.[index]?.name}
                        />
                        <TextField
                          name={`services[${index}].fee`}
                          label="Fee $"
                          type="number"
                          fullWidth
                          value={service.fee || 0}
                          onChange={formik.handleChange}
                          error={formik.touched.services?.[index]?.fee && Boolean(formik.errors.services?.[index]?.fee)}
                          helperText={formik.touched.services?.[index]?.fee && formik.errors.services?.[index]?.fee}
                        />
                        <IconButton
                          onClick={() => {
                            const updatedServices = formik.values.services.filter((_, i) => i !== index);
                            const reindexedServices = updatedServices.map((s, i) => ({
                              ...s,
                              serial_number: i + 1,
                            }));
                            formik.setFieldValue('services', reindexedServices);
                          }}
                          aria-label="delete-service"
                        >
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    ))}
                    <Button
                      variant="outlined"
                      style={{ height: '50px', width: '190px', color: '#0A9EF2', borderColor: '#0A9EF2' }}
                      onClick={() =>
                        formik.setFieldValue('services', [
                          ...formik.values.services,
                          { serial_number: formik.values.services.length + 1, name: '', fee: 0 },
                        ])
                      }
                    >
                      Add Service
                    </Button>
                    {typeof formik.errors.services === 'string' && (
                      <FormHelperText error>{formik.errors.services}</FormHelperText>
                    )}
                  </FormControl>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ backgroundColor: '#0A9EF2', maxWidth: 150 }}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={loading ? <CircularProgress size={20} /> : null}
                  >
                    Create Invoice
                  </LoadingButton>
                </Stack>
              </form>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
